/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

ion-menu-button.md {
  --border-radius: 10px;
  margin: 10px;
  --background: var(--ion-color-primary);
  --color: white;
}

.header-md::after {
  background-image: none;
}

ion-backdrop {
  background: white !important;
}

.alert-radio-group::-webkit-scrollbar,
.alert-checkbox-group::-webkit-scrollbar {
  width: 0.5em;
  display: block !important;
}

.alert-radio-group::-webkit-scrollbar-track,
.alert-checkbox-group::-webkit-scrollbar-track {
  box-shadow: inset;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3) !important;
}

.alert-radio-group::-webkit-scrollbar-thumb,
.alert-checkbox-group::-webkit-scrollbar-thumb {
  background-color: #585b8f99 !important;
  outline: 2px solid #585b8f33 !important;
}

.textSelectable {
  user-select: text !important;
}

@media only screen and (min-width: 768px) {
  .small-modal {
    --height: 40vh;
  }
}

ion-alert.alertGeneral {
  --min-height: 230px;
  --min-width: 400px;
}
ion-alert.alertGeneral .alert-wrapper {
  display: flex;
  justify-content: space-between;
}
ion-alert.alertGeneral .alert-button-group {
  display: flex;
  justify-content: space-around;
  margin-bottom: 5%;
}
ion-alert.alertGeneral .alert-head {
  display: flex;
  justify-content: center;
}
ion-alert.alertGeneral .alert-message {
  text-align: center;
}

ion-alert.alertGeneral button.alert-button.alert-button-confirm {
  background-color: var(--ion-color-primary);
  color: var(--ion-color-primary-contrast);
  width: 30%;
  padding: 10px;
  border-radius: 12px;
  .alert-button-inner {
    justify-content: center;
  }
}
ion-alert.alertGeneral button.alert-button.alert-button-cancel {
  background-color: var(--ion-color-danger);
  color: var(--ion-color-success-contrast);
  width: 30%;
  padding: 10px 25px;
  border-radius: 12px;
  .alert-button-inner {
    justify-content: center;
  }
}
ion-modal.piar-modal {
  --width: 500px;
  --min-width: 250px;
  // --min-height: 250px;
  --height: 300px;
  --border-radius: 15px;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
}
